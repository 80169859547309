<template>
<!--  客户账户管理-->
  <div class="customerAccount">
    <a-spin :indicator="indicator" :spinning="spinning">
    <div class="select_Info">
      <div>
        状态：&nbsp;
        <a-radio-group  @change="openChange" button-style="solid">
          <a-radio-button :value= 1 >
            全部
          </a-radio-button>
          <a-radio-button :value= 2 >
            已启用
          </a-radio-button>
          <a-radio-button :value= 3 >
            已禁用
          </a-radio-button>
        </a-radio-group>
      </div>
      <div style="margin-left: 100px">
        姓名:  &nbsp;<a-input v-model="searchName" placeholder="输入姓名搜索" style="width: 210px" size="default" />
        <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
        <a-button style="margin-left: 10px"  @click="searchChange">重置</a-button></div>
    </div>
    <div class="table_Info">
      <a-button type="primary" @click="addBtn">新增</a-button>
      <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="record => record.id"   :pagination="false"
      >
      <span slot="switch" slot-scope="text,item">
        <a-switch :defaultChecked="item.status=== 'VALID' ? true:false" checked-children="开" un-checked-children="关"  @change="switchChange(item.id,item.status)" />
      </span>
        <span slot="operate" slot-scope="text,item">
        <a @click="editBtn(item.id,item)"> 修改</a>
      </span>
        <span slot="time" slot-scope="text,item">
        {{item.created_time | formatDate('YYYY-MM-DD HH:mm:ss')}}
      </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="tableChange" />
      </div>
    </div>

    <a-drawer
        title="新增"
        width="500"
        :visible="addShow"
        @close="()=>{this.addShow = false}"
    >
      <a-form-model  :model="addForm" hideRequiredMark :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem">
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="tel">
          <a-input v-model="addForm.tel" />
        </a-form-model-item>
        <a-form-model-item label="单位" prop="company">
          <a-input v-model="addForm.company" />
        </a-form-model-item>
        <a-form-model-item label="角色" prop="rname">
          <a-select placeholder="" v-model="rname" >
            <a-select-option v-for="item in RulesTable" :key="item.id" @click="ruleAdd(item.id,item.name)" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="涉及业务" prop="report">
          <a-input v-model="addForm.report" type="textarea" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>
    <a-drawer
        title="编辑"
        width="500"
        :visible="editShow"
        @close="()=>{this.editShow = false}"
    >
      <a-form-model  :model="editForm" hideRequiredMark :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem">
        <a-form-model-item label="姓名" prop="user_name">
          <a-input v-model="editForm.user_name" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="tel">
          <a-input v-model="editForm.tel" />
        </a-form-model-item>
        <a-form-model-item label="单位" prop="company">
          <a-input v-model="editForm.company" />
        </a-form-model-item>
        <a-form-model-item label="角色" prop="rname">
          <a-select placeholder="" v-model="editForm.rname" >
            <a-select-option v-for="item in RulesTable" :key="item.id" @click="ruleEdit(item.id,item.name)" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="涉及业务" prop="report">
          <a-input v-model="editForm.report" type="textarea" />
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
          取消
        </a-button>
        <a-button type="primary" @click="editOk">
          确认
        </a-button>
      </div>
    </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import {getRules, getUserList, getUserRules, postUsers, putUsers, putUserSwitch} from "@/service/menuManagement_api";

import moment from "moment";
export default {
  name: "customerAccount",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      addShow:false,
      editShow:false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      searchName:'',
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      addForm:{

      },
      editForm:{

      },
      rname:'',
      columns:[
        {
          title: '姓名',
          dataIndex: 'user_name',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',

        },
        {
          title: '单位',
          dataIndex: 'company',

        },
        {
          title: '角色',
          dataIndex: 'rname',

        },
        {
          title: '涉及业务',
          dataIndex: 'report',

        },
        {
          title: '状态',
          scopedSlots: { customRender: 'switch' },

        },
        {
          title: '注册时间',
          scopedSlots: { customRender: 'time' },

        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },

        },

      ],
      InfoTable:[],
      RulesTable:[],
      editId:'',
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        user_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        tel:[
          {required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        company:[
          {required: true, message: '请填写单位', trigger: 'blur' }
        ],

        report:[
          {required: true, message: '请填写设计业务', trigger: 'blur' }
        ],
      },
    }
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"客户账户管理")
  },
  mounted() {
    this.getUser()
  },
  methods:{
    async getUser(pageNum,status,name){
      this.spinning = true
      const Info = await  getUserList(pageNum,status,name)
      if(Info.code === 0){
        this.InfoTable = Info.data.rr
        this.pagination.total = Info.data.count
      }
      this.spinning = false
    },
    tableChange(e){
      this.getUser(e)
    },
    searchOk(){
      this.getUser(1,'',this.searchName)
      this.pagination.current = 1
    },
    searchChange(){
      this.getUser()
      this.searchName = ''
    },
    //状态搜索
    openChange(e){
      switch (e.target.value){
        case 1:
          this.getUser()
          break;
        case 2 :
          this.getUser(1,'VALID')
          break;
        case 3:
          this.getUser(1,'INVALID')
          break;
      }
    },
    //确认新增
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if (valid){
          const Upload = await postUsers(this.addForm)
          if (Upload.code===0){
            //刷新列表
            await this.getUser(this.pagination.current)
            this.$message.success("添加成功")
            //清空输入框
            this.$refs.addItem.resetFields();
            //关闭对话框
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //添加按钮
    async addBtn(){
      this.addShow=true
      const Info = await getUserRules()
      if(Info.code === 0){
        this.RulesTable = Info.data.rows
      }
    },
    //角色选择
    ruleAdd(id,name){
      this.addForm.roleId = id
      this.rname = name
    },
    ruleEdit(id,name){
      this.editForm.roleId = id
      this.rname = name
    },
    async editBtn(id,ref){
      this.editShow =true
      this.editId = id
      const Info = await getUserRules()
      if(Info.code === 0){
        this.RulesTable = Info.data.rows
      }
      this.editForm = JSON.parse(JSON.stringify(ref))
    },
    //确认修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if (valid){
          const edit = this.editForm
          const data = {
            name : edit.user_name,
            tel : edit.tel,
            roleId : edit.roleId,
            company : edit.company,
            report : edit.report
          }
          const Upload = await putUsers(this.editId,data)
          if (Upload.code===0){
            //刷新列表
            await this.getUser(this.pagination.current)
            this.$message.success("修改成功")
            //清空输入框
            this.$refs.editItem.resetFields();
            //关闭对话框
            this.editShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //状态修改
    async switchChange(id,checked){
      if (checked === 'INVALID'){
        const data = {
          status : 'VALID'
        }
      const Info =  await putUserSwitch(id,data)
        if (Info.code===0){
          await this.getUser(this.pagination.current)
          this.$message.success('已开启')
        }
      }else {
          const data = {
            status : 'INVALID'
          }
          const Info =  await putUserSwitch(id,data)
          if (Info.code===0){
            await this.getUser(this.pagination.current)
            this.$message.success('已关闭')
          }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.customerAccount{
  margin: -15px -15px ;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    min-height: 400px;
    background-color: white;
  }
}
</style>
